<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Evaluation</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">
            <p>Your feedback helps us to maintain the quality of SR E-learning courses. Thanks for taking the time
                to give feedback so we can incorporate this into future e-learning courses.</p>

            <b-field v-for="item in form" :key="item.id" :label="item.label">
                <b-input v-if="item.type==1" v-model="item.value" type="textarea"></b-input>
                <div v-else-if="item.type==2" class="block">
                    <b-radio v-model="item.value"
                             native-value="yes">
                        Yes
                    </b-radio>
                    <b-radio v-model="item.value"
                             native-value="no">
                        No
                    </b-radio>
                </div>
                <b-rate v-else-if="item.type==3" size="is-large" v-model="item.value" icon-pack="fas"></b-rate>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$emit('close')">Close</button>
            <button class="button is-danger" @click="submit">Submit</button>
        </footer>
    </div>
</template>
<script>

export default {
    data() {
        return {
            form: null
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.form = [
                {
                    label: "What was the most valuable aspect of the course?",
                    type: 1,
                    value: null
                },
                {
                    label: "How will this course change your practice?",
                    type: 1,
                    value: null
                },
                {
                    label: "Would you recommend this course to others?",
                    type: 2,
                    value: "yes"
                }, {
                    label: "How relevant and useful did you find the course?",
                    type: 3,
                    value: null,
                }, {
                    label: "Was the course well-structured and clear?",
                    type: 3,
                    value: null,
                }, {
                    label: "Do you have any recommendations for future courses on this topic?",
                    type: 1,
                    value: null,
                }
            ];
        },
        submit() {
            axios.post(api_prefix + "evaluation", {course_id: this.$route.params.id, data: this.form}).then(r => {
                this.$emit("close")
                this.init()
                this.$buefy.snackbar.open({
                    duration: 5000,
                    message: 'Success',
                    type: 'is-success',
                    position: 'is-top-right',
                    queue: false,
                })
            })
        }
    }
}
</script>
