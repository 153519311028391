<template>
    <div class="container evaluation-wrap">
        <breadcrumb :items="[{title:'Evaluation',name:'evaluation'}]"></breadcrumb>
        <div class="columns">
            <div class="column is-one-quarter">
                <side-bar-categories v-on:set-category="setCategory"></side-bar-categories>
            </div>
            <div class="column">
                <h1>Evaluation</h1>
                <p>As a new service we are keen to hear what you think of SR e-Learning.</p>
                <div v-for="item in form" :key="item.id">
                    <b-field :label="item.label">
                        <b-input v-if="item.type==1" v-model="item.value" type="textarea"></b-input>
                        <div v-else-if="item.type==2" class="block">
                            <b-radio v-model="item.value"
                                     native-value="yes">
                                Yes
                            </b-radio>
                            <b-radio v-model="item.value"
                                     native-value="no">
                                No
                            </b-radio>
                        </div>
                        <b-rate v-else-if="item.type==3" size="is-large" v-model="item.value" icon-pack="fas"></b-rate>
                        <b-input v-else v-model="item.value"></b-input>
                    </b-field>
                </div>
                <p class="control">
                    <button @click="submit" class="button is-danger">
                        Submit
                    </button>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb";
import {mapState} from "vuex";
import SideBarCategories from "../components/SideBarCategories";

export default {
    components: {SideBarCategories, Breadcrumb},
    computed: mapState([
        // map this.count to store.state.count
        'categories',
        'user'
    ]),
    data() {
        return {
            form: null
        }
    },
    created() {
        this.init()
    },
    methods: {

        init() {
            this.form = [
                {
                    label: "Please describe what you find most useful about the SR e-Learning service.",
                    type: 1,
                    value: null
                }, {
                    label: "What improvements would you like to see us make to the SR e-Learning service?",
                    type: 1,
                    value: null
                }
                , {
                    label: "How useful is SR e-Learning service?",
                    type: 3,
                    value: null
                }
                , {
                    label: "How would you rate the ease of navigation of this service?",
                    type: 3,
                    value: null
                }
                , {
                    label: "Were all the images and text in the course clearly visible?",
                    type: 2,
                    value: "yes"
                }
                , {
                    label: "Would you recommend SR e-Learning to others?",
                    type: 2,
                    value: "yes"
                }, {
                    label: "Do you have any recommendations for future course topics?",
                    type: 1,
                    value: null
                }, {
                    label: "Would you like to complete more courses through SR e-Learning?",
                    type: 2,
                    value: "yes"
                },
                {
                    label: "Would you like to complete more courses through SR e-Learning?",
                    type: 4,
                    value: null
                },

            ];
        },
        setCategory(id) {
            let courses = null
            if (id != null) {
                courses = this.categories[id].courses
            }

            this.$router.push({name: 'home', params: {courses: courses}})
        },
        submit() {
            axios.post(api_prefix + "evaluation", {course_id: 0, data: this.form}).then(r => {
                this.$router.push({name: 'home'})
                this.$buefy.snackbar.open({
                    duration: 5000,
                    message: 'Thank you for your evaluation',
                    type: 'is-success',
                    position: 'is-top-right',
                    queue: false,
                })
            })
        }
    }
}
</script>
<style>
h1 {
    font-size: 30px;
    font-weight: 700;
    color: #18252D;
}
.evaluation-wrap p{
    line-height: 4;
}


</style>
