import Vue from 'vue'
import Router from 'vue-router'

import Dashboard from "./pages/Dashboard";
import About from "./pages/About";
import Library from "./pages/Library";
import Evaluation from "./pages/Evaluation";
import Course from "./pages/Course";
import Question from "./pages/Question";
import Score from "./pages/Score";


Vue.use(Router)

var metaBase = "- SR e-learning";
export default new Router(
    {
        mode: 'history',
        base: process.env.BASE_URL,
        routes: [
            {
                path: '/home',
                name: 'home',
                component: Dashboard,
                meta: {
                    title: 'Home' + metaBase,
                    name: 'WELCOME TO SR E-LEARNING'

                }
            },
            {
                path: '/about',
                name: 'about',
                component: About,
                meta: {
                    title: 'About' + metaBase,
                    name: 'ABOUT SR E-LEARNING'

                }
            },
            {
                path: '/user_library',
                name: 'user.library',
                component: Library,
                meta: {
                    title: 'User Library' + metaBase,
                    name: 'YOUR LIBRARY'

                }
            },
            {
                path: '/evaluation',
                name: 'evaluation',
                component: Evaluation,
                meta: {
                    title: 'Evaluation' + metaBase,
                    name: 'EVALUATION'
                }
            },
            {
                path: '/course/:id',
                name: 'course',
                component: Course,
                meta: {
                    title: 'Course' + metaBase,
                    name: 'course'
                }
            },
            {
                path: "/score/:id",
                name: "score",
                component: Score,
                meta: {
                    title: 'Your Score' + metaBase,
                    name: 'Your Score'
                }
            },
            {
                path: '/course_question/:id',
                name: 'course.question',
                component: Question,
                meta: {
                    title: 'Course' + metaBase,
                    name: 'question'
                }
            },


        ],
    })

