<template>
  <div class="container">
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
    <breadcrumb :items="[{ title: 'All Courses', name: 'home' }]"></breadcrumb>

    <div class="columns">
      <div class="column is-one-quarter">
        <side-bar-categories
          v-on:set-category="setCategory"
        ></side-bar-categories>
      </div>
      <div class="column">
        <h1>Welcome to SR e-Learning</h1>
        <p>
          A wide range of SR e-Learning courses are now available below.
          Complete a course, see it in your library, earn CME credits and we
          will lodge these for you with RNZCGP. Check back regularly for more
          new courses and features.
        </p>
        <search-filter
          v-on:filter-order="orderBy"
          v-on:filter-name="setName"
        ></search-filter>
        <search-results :items="courses"></search-results>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb";
import SideBarCategories from "../components/SideBarCategories";
import SearchResults from "../components/SearchResults";
import SearchFilter from "../components/SearchFilter";
import { mapState } from "vuex";

export default {
  components: { SideBarCategories, Breadcrumb, SearchResults, SearchFilter },
  computed: mapState([
    // map this.count to store.state.count
    "categories",
  ]),

  watch: {
    $route() {
      this.filter.category_id = this.$route.query.category_id;
      this.getCourses();
    },
  },
  created() {
    this.filter.category_id = this.$route.query.category_id;
    this.getCourses();
  },
  data() {
    return {
      courses: null,
      filter: {
        category_id: this.$route.params.category_id,
        courses: this.$route.params.courses,
        name: this.$route.params.name,
        sort_by: this.$route.params.sort_by,
      },
      isLoading: true,
    };
  },
  methods: {
    setCategory(id) {
      this.filter.category_id = id;
      this.getCourses();
    },
    setName(name) {
      this.filter.name = name;
      this.getCourses();
    },
    orderBy(sort) {
      this.filter.sort_by = sort;
      this.getCourses();
    },
    getCourses() {
      this.isLoading = true;
      axios.get(api_prefix + "course", { params: this.filter }).then((r) => {
        this.courses = r.data.success;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 30px;
  font-weight: 700;
  color: #18252d;
}

p {
  font-size: 18px;
  margin: 10px 0;
}
</style>
