<template>
  <div class="sidebar-wrap">
    <b-menu>
      <b-menu-list>
        <b-menu-item
          class="first-title"
          @click="setCategory(null)"
          label="All Courses"
        ></b-menu-item>
      </b-menu-list>
      <b-menu-list v-if="categories" label="COURSES BY CATEGORY">
        <b-menu-item
          v-for="category in categories"
          v-bind:key="category.id"
          @click="setCategory(category.id)"
          :label="category.name + ' (' + category.total + ')'"
        ></b-menu-item>
      </b-menu-list>
      <b-menu-list v-if="latest" label="LATEST COURSES">
        <b-menu-item
          v-for="course in latest"
          :key="course.id"
          :label="course.title"
          :to="{ name: 'course', params: { id: course.id } }"
          tag="router-link"
        >
        </b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>
import {mapState} from 'vuex'
<script>
import { mapState } from "vuex";

export default {
  computed: mapState(["categories", "latest"]),
  methods: {
    setCategory(id) {
      this.$router
        .push({ name: "home", query: { category_id: id } })
        .catch((r) => {});
      //   this.$emit("set-category", id);
    },
    getCourse(id) {
      this.$router
        .push({ name: "course", params: { id: id } })
        .catch((r) => {});
    },
  },
};
</script>
<style scoped>
.first-title {
  font-size: 18px;
}

.menu-list {
  font-size: 14px;
  color: rgba(72, 87, 103, 1);
  font-weight: 500;
}

.menu-label {
  font-size: 12px;
  color: #485767;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
<style>
.first-title span {
  color: #e30613 !important;
}
</style>
