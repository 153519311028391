<template>
  <div v-if="item" class="card">
    <div class="columns">
      <div class="column is-one-quarter">
        <figure class="image">
          <img class="is-rounded" :src="thumb" />
        </figure>
      </div>
      <div class="column">
        <h2>{{ item.title }}</h2>
        <p class="small-title">DATE LISTED</p>
        <p class="date">{{ item.created_at | date }}</p>
        <br />
        <p class="small-title">RNZCGP CPD</p>
        <p>
          <i class="fas fa-graduation-cap"></i> {{ item.cme_credits }} credits /
          15 mins
        </p>
      </div>
      <div class="column is-one-quarter">
        <ul class="button-group">
          <li>
            <b-button
              @click="openPDF"
              type="is-danger"
              icon-pack="fas"
              icon-left="file-alt"
              expanded
            >
              READ ARTICLE
            </b-button>
          </li>
          <li>
            <b-button
              @click="openQuestion"
              type="is-danger"
              icon-pack="fas"
              icon-left="graduation-cap"
              expanded
              >START COURSE
            </b-button>
          </li>
        </ul>
        <ul class="link-group">
          <li>
            <a @click="printPDF"><i class="fas fa-print"></i> Print Article</a>
          </li>
          <li>
            <a @click="isComponentModalActive = true"
              ><i class="fas fa-clipboard"></i> Evaluation</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-quarter">
        <p class="label-title">COURSE SUMMARY</p>
      </div>
      <div class="column">
        <p class="course-content" v-html="item.summary"></p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-quarter">
        <p class="label-title">E-LEARNING OUTCOME</p>
      </div>
      <div class="column">
        <p class="course-content" v-html="item.outcome"></p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-quarter"><p class="label-title">AUTHOR</p></div>
      <div class="column">
        <div class="columns">
          <div v-for="auther in item.authors" class="column is-one-quarter">
            <figure class="image is-48x48">
              <img :src="imgPath + auther.thumb" alt="Placeholder image" />
            </figure>
            <div class="media-content">
              <p class="subtitle is-6 break-word-normal">{{ auther.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-quarter"><p class="label-title">NOTE</p></div>
      <div class="column">
        <p class="course-content">
          Once you click READ ARTICLE a new window containing the article should
          appear. If the article does not open, check your download folder as
          some browsers default to downloading a pdf rather than opening it -
          this is a local browser setting.
        </p>
      </div>
    </div>

    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template #default="props">
        <evaluation-modal @close="props.close"></evaluation-modal>
      </template>
    </b-modal>
  </div>
</template>
<script>
import print from "print-js";
import EvaluationModal from "../components/EvaluationModal";

export default {
  components: { EvaluationModal },
  props: ["item"],
  data() {
    return {
      thumb: null,
      pdf: null,
      printLink: null,
      imgPath: api_prefix + "file/sr/",
      isComponentModalActive: false,
    };
  },

  filters: {
    date(value) {
      let options = { year: "numeric", month: "long", day: "numeric" };
      let d = new Date(value);
      return d.toLocaleString("en-GB", options);
    },
  },
  watch: {
    item(val) {
      this.getFiles(val.files);
    },
  },
  methods: {
    openPDF() {
      window.open(this.pdf, "_blank");
    },
    openQuestion() {
      this.$router.push({
        name: "course.question",
        params: { id: this.$route.params.id },
      });
    },
    printPDF() {
      printJS(this.pdf);
    },

    getFiles(value) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].type == "thumb") {
          this.thumb = "/v1/file/" + value[i].filename;
        }
        if (value[i].type == "pdf") {
          this.pdf = "/v1/file/" + value[i].filename;
          this.printLink = "/v1/file/print/" + value[i].filename;
        }
      }
    },
  },
};
</script>
<style scoped>
.card {
  padding: 20px;
  margin-top: 20px;
}

.button-group li {
  margin-top: 20px;
}

.link-group {
  margin-top: 20px;
  font-size: 14px;
}

.fas {
  color: #e30613;
  width: 20px;
  text-align: center;
}

h2 {
  font-size: 30px;
  font-weight: 700;
  color: #18252d;
}

.is-48x48 {
  width: 85px;
  height: 110px;
  overflow: hidden;
}

.subtitle {
  font-size: 16px;
  color: #18252d;
  font-weight: 600;
}

.course-content {
  font-size: 16px;
  color: #18252d;
}

.small-title {
  margin-top: 15px;
  font-size: 12px;
  color: #485767;
  font-weight: bold;
  text-transform: uppercase;
}

.label-title {
  font-size: 12px;
  color: #485767;
  font-weight: bold;
  text-transform: uppercase;
  text-align: right;
  padding-top: 2px;
}

.date {
  font-size: 16px;
  color: #485767;
}

.break-word-normal {
    word-break: normal !important;
}
</style>
