<template>
    <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul class="left-list">
            <li>
                <router-link :to="{name:'home'}">SR e-Learning</router-link>
            </li>
            <li v-for="item in items" :class="item.current?'active':''">
                <router-link :to="{name:item.name,query:item.query}">{{ item.title }}</router-link>
            </li>
            <!--            <li><a href=" #
                ">Documentation</a></li>
            -->
            <!--            <li><a href="#">Components</a></li>-->
            <!--            <li class="is-active"><a href="#" aria-current="page">Breadcrumb</a></li>-->

        </ul>
        <ul class="right-list">
            <a @click="back"><i class="fas fa-long-arrow-alt-left"></i> Back</a>
        </ul>
        <div class="split"></div>
    </nav>
</template>
<script>
export default {
    props: ["items"],
    methods: {
        back() {
            if (this.force) {
                this.$router.push('search');
            } else {
                this.$router.back();
            }
        }
    }
}
</script>
<style scoped>
.breadcrumb {
    margin-top: 10px;
}

.breadcrumb i {
    padding-right: 10px;
}

.breadcrumb a {
    color: #485767;
}

.left-list {
    float: left;
    width: 80%;
}

.right-list {
    justify-content: flex-end;
}
</style>
