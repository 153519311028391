<template>
    <div v-if="item" class="container question-wrap">

        <breadcrumb :items="[{title:'All Courses',name:'home'},{title:'Course',name:'course'}]"></breadcrumb>
        <question-header :item="item"></question-header>
        <question-items v-on:new="startAgain" v-on:update-history="updateHistoryId" :history-id="historyId"
                        :items="item.questions"></question-items>
    </div>
</template>
<script>
import QuestionHeader from "../components/QuestionHeader";
import QuestionItems from "../components/QuestionItems";
import Breadcrumb from "../components/Breadcrumb";

export default {
    components: {Breadcrumb, QuestionItems, QuestionHeader},
    data() {
        return {
            historyId: null,
            item: null
        }
    },

    created() {
        this.getQuestions()
    },
    methods: {

        getQuestions() {
            axios.get(api_prefix + 'course/question/' + this.$route.params.id).then(r => {
                this.item = r.data.success
                this.historyId = r.data.history
            });
        },
        startAgain() {
            axios.get(api_prefix + 'score/new/' + this.$route.params.id).then(r => {
                this.historyId = null
            })
        },
        updateHistoryId(param) {
            axios.post(api_prefix + "course/question/" + this.$route.params.id, param).then(r => {
                this.historyId = r.data.success
            });
        }


    }
}
</script>
