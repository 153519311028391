<template>
    <div class="container">
        <breadcrumb :items="[{title:'About',name:'about'}]"></breadcrumb>

        <div class="columns">
            <div class="column is-one-quarter">
                <side-bar-categories v-on:set-category="setCategory"></side-bar-categories>
            </div>
            <div class="column">
                <h1>About SR e-Learning</h1>
                <p>At the request of GP’s and Specialists we are delighted to introduce the new SR e-Learning service –
                    a library of CPD-accredited learning modules to expand your knowledge and earn CPD points.
                </p>
                <p>Nurses can also complete Courses and print their certificate for CME.</p>
                <br/>
                <b-button @click="goHome" type="is-danger">Start Learning</b-button>
                <div class="split"></div>
                <h2>Read Articles</h2>
                <p>NZ Medical Specialists have taken the time to write NZ specific summaries to assist in the treatment
                    and referral of a wide range of conditions. Simply complete a course and your points will be lodged
                    directly with the RNZCGP.</p>
                <p>The SR Articles are intended to be summary articles that you can read when you have a short break in
                    your day (eg patient do not show). You can also print these articles for your patients.
                </p>
                <div class="split"></div>
                <h2>Start a course</h2>
                <p>Following each article is a short set of questions. You need to achieve 80% correct responses to
                    attain the allocated credit. Courses you complete are listed in Your Library.
                </p>
                <p>
                    <b>New Courses are being added throughout the year, so check back regularly for the latest
                        Specialist
                        articles.
                    </b>
                </p>
                <div class="split"></div>
                <h2>Earn CPD credits
                </h2>
                <p>Each course constitutes an RNZCGP Endorsed Activity for Individually Planned Learning (0.25 Credit).
                    Your Course Achievements will be lodged for you directly with RNZCGP by the SR Service.

                </p>
                <div class="split"></div>
                <h2>Certificate
                </h2>
                <p>SR e-Learning provides your CME Certificate of Completion for each course. These can be downloaded at
                    any time.

                </p>
                <div class="split"></div>
                <h2>Evaluation
                </h2>
                <p>As a new e-learning service we welcome your evaluations and suggestions. This can be either general
                    for the service as a whole or specific in regard to particular courses.

                </p>
                <div class="split"></div>

            </div>
        </div>
    </div>
</template>

<script>
import SideBarCategories from "../components/SideBarCategories";
import {mapState} from "vuex";
import Breadcrumb from "../components/Breadcrumb";

export default {
    components: {Breadcrumb, SideBarCategories},
    computed: mapState([
        // map this.count to store.state.count
        'categories'
    ]),
    data() {
        return {
            item: null
        }
    },
    created() {
    },

    methods: {
        goHome() {
            this.$router.push({name: 'home'})
        },
        setCategory(id) {
            let courses = null
            if (id != null) {
                courses = this.categories[id].courses
            }

            this.$router.push({name: 'home', params: {courses: courses}})
        },

    }
}
</script>
<style scoped>
h1 {
    font-size: 30px;
    font-weight: 700;
    color: #18252D;
}

h2 {
    color: #18252D;
    font-size: 20px;
    font-weight: 700;
}

p {
    margin-top: 5px;
    font-size: 18px;
}
</style>
