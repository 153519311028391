<template>
  <div class="result-wrap">
    <div v-for="(item, i) in items" class="card" :key="i">
      <div class="columns">
        <div class="column is-one-quarter" @click="goCourse(item.id)">
          <figure class="image">
            <img class="is-rounded" :src="getThumb(item.files)" />
          </figure>
        </div>
        <div class="column">
          <div class="item-wrap">
            <h3>{{ item.title }}</h3>
            <span class="item-date">{{ item.date_start | date }}</span>
            <p>{{ item.summary }}</p>
          </div>
        </div>
        <div class="column is-one-quarter">
          <b-menu>
            <b-menu-list label="Author">
              <b-menu-item
                v-for="author in item.authors"
                :key="author.id"
                icon-pack="fas"
                icon="user"
                :label="author.name"
              ></b-menu-item>
            </b-menu-list>
            <div class="splitter"></div>
            <b-menu-list label="RNZCGP CPD">
              <b-menu-item
                icon-pack="fas"
                icon="graduation-cap"
                disabled
                :label="item.cme_credits + ' credits'"
              ></b-menu-item>
            </b-menu-list>
          </b-menu>
          <b-button
            @click="goCourse(item.id)"
            type="is-danger"
            icon-pack="fas"
            icon-left="graduation-cap"
            expanded
            >COURSE</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["items"],
  filters: {
    date(value) {
      let options = { year: "numeric", month: "long", day: "numeric" };
      let d = new Date(value);
      return d.toLocaleString("en-GB", options);
    },
  },
  methods: {
    goCourse(id) {
      this.$router.push({ name: "course", params: { id: id } });
    },
    getThumb(value) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].type == "thumb") {
          return "/v1/file/" + value[i].filename;
        }
      }
    },
  },
};
</script>
