<template>
    <div class="question-items-wrap">
        <h2>There are {{ items.length }} questions for this course</h2>
        <b-steps v-if="!loading" v-model="questionIndex" size="is-small" icon-pack="fas" mobile-mode="minimalist"
                 :has-navigation="false">
            <b-step-item v-for="(item,index) in items" :step="index+1" :clickable="true" :key="index">
                <div class="card">
                    <h2 class="question-title">{{ item.title }}</h2>
                    <div v-if="item.type=='single'" class="question-choice">
                        <div v-for="option in item.options" :key="option.id" class="field">
                            <b-radio v-on:input="nextQuestion" v-model="selected[index]"
                                     :native-value="option.id">
                                {{ option.title }}
                            </b-radio>
                        </div>
                    </div>
                    <div v-else class="question-choice">
                        <div v-for="option in item.options" :key="option.id" class="field">
                            <div class="field">
                                <b-checkbox :native-value="option.id" v-model="multiple">{{
                                        option.title
                                    }}
                                </b-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </b-step-item>
        </b-steps>
        <div class="button-control">
            <b-button @click="prevQuestion" type="is-dark">Prev</b-button>
            <b-button v-if="end" @click="nextQuestion" type="is-danger">Next</b-button>
            <b-button v-else @click="finish" type="is-danger">Finish</b-button>

        </div>
    </div>
</template>

<script>
export default {
    props: ['items', 'historyId'],
    data() {
        return {
            selected: [],
            multiple: [],
            loading: true,
            questionIndex: 0,
        }
    },
    created() {
        this.historyCheck();
    },
    computed: {
        end() {
            if (this.questionIndex == (this.items.length - 1)) {
                return null
            } else {
                return 1
            }
        }
    }
    ,
    methods: {

        historyCheck() {
            if (this.historyId) {
                this.confirm()
            } else {
                this.loading = false
            }
        },
        confirm() {
            this.$buefy.dialog.confirm({
                message: 'Continue on from last course?',
                cancelText: "Start New",
                confirmText: "Continue",
                onCancel: () => {
                    this.loading = false
                    this.new()
                },
                onConfirm: () => {
                    this.loadHistory();
                }
            })
        },
        finish() {
            this.selected = this.selected.concat(this.multiple)
            axios.post(api_prefix + "course/finish/" + this.historyId, {
                data: this.selected,
            }).then(r => {
                this.$router.push({name: "score", params: {id: this.historyId}})
            })
        },
        new() {
            this.$emit("new")

        },
        loadHistory() {
            axios.get(api_prefix + 'course/load/' + this.historyId).then(r => {
                this.history = r.data.success;
                let flag = false;
                for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i].id in this.history) {
                        this.selected[i] = this.history[this.items[i].id]
                    } else {
                        if (!flag) {
                            flag = true;
                            this.questionIndex = i
                        }
                    }
                }
                this.loading = false
            });
        },
        nextQuestion() {
            if (this.questionIndex < this.items.length - 1) {
                if (this.selected.length > 0 || this.multiple.length > 0) {
                    this.selected = this.selected.concat(this.multiple)
                    let param = {
                        data: this.selected,
                        history: this.historyId
                    }
                    this.$emit("update-history", param);
                }
                this.questionIndex++;
            }
        },
        prevQuestion() {
            if (this.questionIndex > 0) {
                this.questionIndex--;
            }
        }
    }
}
</script>

<style scoped>
.card {
    padding: 25px;
}

.button-control {
    text-align: right;
    padding-right: 16px;
}

h2 {
    font-weight: 700;
    margin-bottom: 25px;
    margin-top: 25px;
}

.question-title {
    font-size: 20px;
    font-weight: bold;
}

.question-choice {
    margin-top: 20px;
    margin-bottom: 20px;
}

.radio {
    font-size: 18px;
}

</style>
<style>
.question-choice .check {
    font-size: 13px;
}
</style>
