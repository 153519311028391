<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Completed Courses
            </p>
        </header>
        <div class="card-body">
            <div class="card-content">
                <table v-if="items">
                    <tr>
                        <td></td>
                        <td>Course</td>
                        <td>Author</td>
                        <td>RNZCGP CPD</td>
                        <td>Date Started</td>
                    </tr>
                    <tr v-for="item in items" :key="item.id" v-if="item.pass">
                        <td width="5%">
                            <b-button
                                title="Download Certificate"
                                type="is-text"
                                :rounded="true"
                                tag="a"
                                target="_blank"
                                :href="'/v1/score/certificate/'+item.id"
                                icon-pack="fas"
                                icon-left="file-download"
                                icon-size="big"
                                class="download-button"
                            />
                        </td>
                        <td width="35%">
                            <router-link :to="{name:'score',params:{id:item.id}}"><i class="fas fa-search"></i>
                                {{ item.title }}
                            </router-link>
                        </td>
                        <td width="30%">
                            {{ item.author }}
                        </td>
                        <td width="15%">
                            {{ item.cme }}
                        </td>
                        <td width="20%">
                            {{ item.date }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["items"]

}
</script>
<style scoped>
table {
    width: 100%;
}

table tr {
    height: 40px;
}

.download-button {
    box-shadow: none;
}

.download-button >>> i {
    color: #E30613;
    font-size: 1.1em;
}

.failed {
    background: red;
    color: white;
    padding: 2px 15px 2px 15px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
}

.passed {
    background: lightgreen;
    color: white;
    padding: 2px 15px 2px 15px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
}
</style>
