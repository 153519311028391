<template>
    <div class="header-wrap">
        <div class="container">
            <div class="header-text">
                <p>Kia Ora, {{ this.user.first_name |name }}</p>
                <h2>
                    {{ this.title }}

                </h2>
            </div>
            <div class="endorsed-logo">
                <img src="/img/endorsed_rnzcgp_2024.png" style="height: 150px" alt="img-college"/>
                <img src="/img/endorsed_cpd_activity.png" alt="img-college"/>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'

export default {
    props: ['title'],
    filters: {
        name(value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },
    computed: mapState([
        // map this.count to store.state.count
        'user'
    ]),

}
</script>
<style scoped>
    .endorsed-logo {
        height: 200px;
        display: inline;
        position: absolute;
        right: 0;
        bottom: -15px;
        overflow: hidden;
    }
    .endorsed-logo img{
        height: 75px;
        vertical-align: middle;
    }
</style>
