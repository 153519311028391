<template>
    <b-navbar wrapper-class="container">
        <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ name: 'home' }">
                <img
                    src="/img/logo_2024.png"
                    alt="SR E-LEARNING"
                >
            </b-navbar-item>
        </template>
        <template slot="end">
            <b-navbar-item tag="router-link" :to="{ name:'about' }">
                About
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ name:'user.library' }">
                Your Library
            </b-navbar-item>
            <!--            <b-navbar-dropdown label="Info">-->
            <b-navbar-item tag="router-link" :to="{ name:'evaluation' }">
                Evaluation
            </b-navbar-item>
            <b-navbar-item v-if="user.id>1" @click="logout">
                Logout
            </b-navbar-item>
            <b-navbar-item class="search-button-menu" v-else @click="backToSr">
                <i class="fas fa-search"></i> Search Specialists
            </b-navbar-item>
            <!--            </b-navbar-dropdown>-->
        </template>
    </b-navbar>
</template>

<script>
import {mapState} from "vuex";

export default {
    computed: mapState([
        // map this.count to store.state.count
        'user'
    ]),
    methods: {
        backToSr() {
            window.open(process.env.MIX_SR + "/search")
        },
        logout() {
            axios.post('/logout')
                .then(() => {
                    this.$store.commit('reset')
                    location.href = ''
                })
        }
    }
}
</script>
<style scoped>

.search-button-menu{
    background-color: #E30613!important;
    padding: 0px 25px 0px 25px;
    height: 40px;
    margin-top: 10px;
    margin-left: 10px;
    color: white!important;
}
</style>
