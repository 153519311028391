<template>
  <div class="container">
    <breadcrumb
      :items="[
        { title: 'All Courses', name: 'home' },
        { title: 'Course', name: 'course' },
      ]"
    ></breadcrumb>
    <div class="columns">
      <div class="column is-one-quarter">
        <side-bar-categories
          v-on:set-category="setCategory"
        ></side-bar-categories>
      </div>
      <div class="column">
        <course-detail :item="this.item"></course-detail>
      </div>
    </div>
  </div>
</template>

<script>
import SideBarCategories from "../components/SideBarCategories";
import CourseDetail from "../components/CourseDetail";
import { mapState } from "vuex";
import Breadcrumb from "../components/Breadcrumb";

export default {
  components: { Breadcrumb, CourseDetail, SideBarCategories },
  computed: mapState([
    // map this.count to store.state.count
    "categories",
  ]),
  data() {
    return {
      item: null,
      course_id: this.$route.params.id,
    };
  },
  watch: {
    "$route.params.id"() {
      this.getCourse();
    },
  },
  created() {
    this.getCourse();
  },

  methods: {
    setCategory(id) {
      let courses = null;
      //   if (id != null) {
      //     courses = this.categories[id].courses;
      //   }

      //   this.$router.push({ name: "home", params: { courses: courses } });
    },
    getCourse() {
      axios.get(api_prefix + "course/" + this.$route.params.id).then((r) => {
        this.item = r.data.success;
      });
    },
  },
};
</script>
