<template>
    <div class="container">
        <breadcrumb :items="[{title:'Your Library',name:'user.library'}]"></breadcrumb>
        <div class="columns">
            <div class="column is-one-quarter">
                <side-bar-categories v-on:set-category="setCategory"></side-bar-categories>
            </div>
            <div class="column">
                <h1>Welcome to your SR e-Learning Library</h1>
                <p>Here you can edit your profile details, view In Progress Courses (parked or not reached pass mark)
                    and view Completed Courses. You can also request your Course Completion Certificate by email. If you
                    have any queries feel free to contact us on <a href="mailto:sr-support@cervinmedia.co.nz">sr-support@cervinmedia.co.nz.</a>

                </p>
                <div class="split"></div>
                <profile-details></profile-details>
                <br/>
                <in-progress-course :items="courses"></in-progress-course>
                <br/>
                <completed-course :items="courses"></completed-course>
            </div>
        </div>
    </div>
</template>

<script>
import SideBarCategories from "../components/SideBarCategories";
import {mapState} from "vuex";
import ProfileDetails from "../components/ProfileDetails";
import CompletedCourse from "../components/CompletedCourse";
import CourseDetail from "../components/CourseDetail";
import InProgressCourse from "../components/InProgressCourse";
import Breadcrumb from "../components/Breadcrumb";


export default {
    components: {Breadcrumb, ProfileDetails, SideBarCategories, CompletedCourse, CourseDetail, InProgressCourse},
    computed: mapState([
        // map this.count to store.state.count
        'categories',
        'user'
    ]),
    data() {
        return {
            item: null,
            courses: null
        }
    },
    created() {
        this.getUserCourse()
    },

    methods: {
        goHome() {
            this.$router.push({name: 'home'})
        },
        setCategory(id) {
            let courses = null
            if (id != null) {
                courses = this.categories[id].courses
            }

            this.$router.push({name: 'home', params: {courses: courses}})
        },
        getUserCourse() {
            axios.get(api_prefix + 'score').then(r => {
                this.courses = r.data.success
            })
        }

    }
}
</script>
<style scoped>


h1 {
    font-size: 30px;
    font-weight: 700;
    color: #18252D;
}

h2 {
    color: #18252D;
    font-size: 20px;
    font-weight: 700;
}

p {
    margin-top: 5px;
    font-size: 18px;
}
</style>
