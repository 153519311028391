import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        /* User */
        user: null,
        settings: null,
        categories: null,
        latest: null,
    },
    mutations: {
        reset(state) {
            // acquire initial state
            state.user = null;
            state.settings = null;
            state.categories = null;
        },
        user(state, payload) {
            state.user = payload

        },
        latest(state, payload) {
            state.latest = payload
        },

        categories(state, payload) {
            state.categories = payload

        },
        settings(state, payload) {
            state.settings = payload
        }
    },

    actions: {
        load(context) {
            return new Promise((resolve, reject) => {
                axios
                    .get(api_prefix + 'user')
                    .then(r => {
                        context.commit('user', r.data.success);
                    })
                    .catch(err => {

                    })
                axios
                    .get(api_prefix + 'course/latest')
                    .then(r => {
                        context.commit('latest', r.data.success);
                    })
                    .catch(err => {

                    })
                axios
                    .get(api_prefix + 'category')
                    .then(r => {
                        context.commit('categories', r.data.success);
                        resolve()
                    })
                    .catch(err => {

                    })

            });
        }
    }

});
