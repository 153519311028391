<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Your Profile Details
            </p>
            <a v-if="user.type" @click="isComponentModalActive = true" class="card-header-icon"
               aria-label="more options">
                <b-icon pack="fas" icon="edit"></b-icon>
                Link Account
            </a>
            <a v-else @click="isComponentModalActive = true" class="card-header-icon" aria-label="more options">
                <b-icon pack="fas" icon="edit"></b-icon>
                Edit
            </a>

            <b-modal
                v-model="isComponentModalActive"
                has-modal-card
                trap-focus
                :destroy-on-hide="false"
                aria-role="dialog"
                aria-modal>
                <template #default="props">
                    <account-modal @close="props.close"></account-modal>
                </template>
            </b-modal>
        </header>
        <div class="card-content">
            <div class="content">
                <p class="table-hints">Please check the details below are correct so that your credits can
                    be correctly lodged
                    with
                    RNZCGP.</p>
                <table>
                    <tr>
                        <td>Name</td>
                        <td>Position</td>
                        <td>
                            <a href="#">
                                <b-icon pack="fas" icon="plus"></b-icon>
                                Add Photo
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td><b>{{ this.user.first_name }} {{ this.user.last_name }}</b></td>
                        <td>{{ this.user.position }}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Qualifications</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="3">Email (for Course Certificates)</td>
                    </tr>
                    <tr>
                        <td colspan="3"><b>{{ this.user.email }}</b></td>
                    </tr>
                    <tr>
                        <td>Bio</td>
                        <td>Special Interests</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Usual Days Worked</td>
                        <td>Open Book</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>

            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import AccountModal from "./AccountModal";

export default {
    components: {AccountModal},
    data() {
        return {
            isComponentModalActive: false
        }
    },
    computed: mapState([
        // map this.count to store.state.count
        'user'
    ]),

}
</script>
<style scoped>
table td {
    border: none;
    font-size: 14px;
}

.table-hints {
    font-size: 14px;
}
</style>
