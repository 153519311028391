<template>
    <div class="filter-wrap">
        <div class="columns">
            <div class="column">
                <b-field position="is-left">
                    <b-input placeholder="Search Courses" v-model="name"
                             type="search" v-on:input="searchName">
                    </b-input>
                    <p class="control">
                        <b-button type="is-primary" icon-pack="fas" @click="searchName"
                                  icon-right="search"/>
                    </p>
                </b-field>
            </div>
            <div class="column">
                <b-field position="is-right">
                    <b-select v-on:input="orderBy" v-model="order" placeholder="Sort By">
                        <option
                            v-for="option in sort"
                            :value="option.id"
                            :key="option.id">
                            {{ option.name }}
                        </option>
                    </b-select>
                    <p class="control">
                        <b-button @click="orderBy" type="is-primary">Sort</b-button>
                    </p>
                </b-field>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sort: [
                {id: 1, name: "Date Listed"},
                {id: 2, name: "Course Title"},
                // {id: 3, name: "Author"},
            ],
            name: null,
            order: null
        }
    },
    methods: {
        searchName() {
            this.$emit('filter-name', this.name);
        },
        orderBy() {
            this.$emit('filter-order', this.order);
        }
    }
}
</script>
