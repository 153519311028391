<template>
    <div id="app">
        <menu-bar/>
        <header-bar v-if="header" :title="name"/>
        <div class="content-container">
            <router-view/>
        </div>
        <footer-bar/>
    </div>
</template>
<script>
import MenuBar from "./shared/MenuBar";
import FooterBar from "./shared/FooterBar";
import HeaderBar from "./shared/HeaderBar";

export default {
    components: {HeaderBar, MenuBar, FooterBar},
    watch: {
        '$route'() {
            this.init()
        }
    },
    data() {
        return {
            name: "WELCOME TO SR E-LEARNING",
            header: true,
            isLoading: true
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            document.title = this.$route.meta.title || 'Your Website'
            this.name = this.$route.meta.name
            if (this.$route.meta.name == 'question')
                this.header = false
        }
    }

}
</script>
