/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

// promise for IE
import 'es6-promise/auto'
import "@babel/polyfill";

/* Core */
import router from './router'
import store from './store'


/* Vue. Main component */
import App from './App.vue'

/* vue add buefy */
import Buefy from 'buefy'
import VueGtag from "vue-gtag";

Vue.use(Buefy)

Vue.use(VueGtag, {
    config: {
        id: "G-YBCHPQ8JFC",
    }
}, router);

require('./extension');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
store.dispatch('load').then(() => {
    new Vue({
        el: '#app',
        store,
        router,
        render: h => h(App),
    }).$mount('#app');
});
