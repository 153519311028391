<template>
    <div class="columns">
        <div class="column is-one-quarter">
            <figure class="image">
                <img class="is-rounded" :src="thumb"/>
            </figure>
        </div>
        <div class="column">
            <p class="date">{{ item.created_at | date }}</p>
            <h2>{{ item.title }}</h2>
            <br/>
            <div class="columns">
                <div class="column">
                    <p class="small-title">AUTHOR</p>
                    <p class="small-author" v-for="author in item.authors"><i class="fas fa-user"></i> {{ author.name }}
                    </p>
                </div>
                <div class="column">
                    <p class="small-title">RNZCGP CPD</p>
                    <p class="small-author"><i class="fas fa-graduation-cap"></i> {{ item.cme_credits }} credits / 15
                        mins</p>
                </div>
            </div>
            <b-button @click="openPDF" type="is-danger">Read Article</b-button>
        </div>
        <div class="column is-one-quarter endorsed-logo">
            <img class="badge" src="/img/endorsed_rnzcgp.png"/>
            <img class="badge" src="/img/endorsed_cpd_activity.png" style="width: 200px"/>
        </div>
    </div>
</template>
<script>
export default {
    props: ['item'],
    data() {
        return {
            thumb: null,
            pdf: null,
            badge: "/img/RNZCGP_CPD_BW_LogoV_2016.png"
        }
    },
    filters: {
        date(value) {
            let options = {year: 'numeric', month: 'long', day: 'numeric'};
            let d = new Date(value);
            return d.toLocaleString('en-GB', options);
        }
    },
    created() {
        this.getFiles()
    },
    methods: {
        getFiles() {
            let files = this.item.files
            for (let i = 0; i < files.length; i++) {
                if (files[i].type == 'thumb') {
                    this.thumb = '/v1/file/' + files[i].filename;
                }
                if (files[i].type == 'pdf') {
                    this.pdf = '/v1/file/' + files[i].filename;
                }
            }
        },
        openPDF() {
            window.open(this.pdf, '_blank')
        },
    }
}

</script>
<style scoped>
.badge {
    width: 145px;
    max-width: 100%;
}

.date {
    font-size: 14px;
}

.fas {
    color: #E30613;
}

h2 {
    font-size: 30px;
    font-weight: 700;
    color: #18252D;
}

.small-author {
    font-weight: 700;
    font-size: 14px;
}

.image {
    width: 210px;
}

.small-title {
    font-size: 10px;
    color: #485767;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.endorsed-logo {
    text-align: center;
}
</style>
