<template>
    <div v-if="item" class="container question-wrap">
        <breadcrumb :items="[{title:'All Courses',name:'home'},{title:'Score',name:'score'}]"></breadcrumb>
        <question-header :item="item.course"></question-header>
        <div class="split"></div>
        <score-board :item="item.score"></score-board>
        <question-item-results></question-item-results>
    </div>
</template>
<script>
import QuestionHeader from "../components/QuestionHeader";
import QuestionItems from "../components/QuestionItems";
import Breadcrumb from "../components/Breadcrumb";
import ScoreBoard from "../components/ScoreBoard";
import QuestionItemResults from "../components/QuestionItemResults";

export default {
    components: {QuestionItemResults, Breadcrumb, QuestionItems, QuestionHeader, ScoreBoard},
    data() {
        return {
            item: null
        }
    },

    created() {
        this.getScore()
    },
    methods: {

        getScore() {
            axios.get(api_prefix + 'score/' + this.$route.params.id).then(r => {
                this.item = r.data.success

            });
        },

    }
}
</script>
