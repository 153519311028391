<template>
    <div v-if="items" class="score-result-wrap">
        <div class="split"></div>
        <div v-for="item in items" class="question-item-wrap" :key="item.id" v-if="!item.correct">
            <h2 class="question-title">{{ item.question }}</h2>
            <div class="question-choice">
                <div v-for="(option,index) in item.options" :key="index">
                    {{ option }}
                </div>
                <div class="wrong-answer">Your Answer: {{ item | wrongAnswer }}</div>
                <div class="right-answer">Correct Answer: {{ item | correctAnswer }}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    data() {
        return {
            items: null,
            selected: []
        }
    },
    filters: {
        wrongAnswer(val) {
            let ans = "";
            for (let i = 0; i < val.user.length; i++) {
                ans += val.options[val.user[i]].slice(0, 1) + ", "
            }
            return ans.replace(/,\s*$/, "");
        },
        correctAnswer(val) {
            let ans = "";
            for (let i = 0; i < val.answer.length; i++) {
                ans += val.options[val.answer[i]].slice(0, 1) + ", "
            }
            return ans.replace(/,\s*$/, "");
        }
    },
    created() {
        this.getResults()
    },
    methods: {
        getResults() {
            axios.get(api_prefix + "score/details/" + this.$route.params.id).then(r => {
                this.items = r.data.success
            })
        }
    }
}
</script>
<style scoped>
.question-title {
    font-size: 20px;
    font-weight: bold;
}

.wrong-answer {
    color: red;
}

.right-answer {
    color: green;
}
</style>
