<template>
    <div v-if="item" class="score-board-wrap">
        <b-notification
            v-if="item.percentage < 80"
            type="is-danger is-light"
            role="alert"
        >
            <h3>Your Scored :{{ item.score }}</h3>
            <br/>
            <p>You have not completed this course. You need 80% to pass.</p>
            <br/>
            <b-button @click="tryAgain" type="is-danger">Try Again</b-button>
            <b-button @click="allCourse" type="is-danger">All Courses</b-button>
        </b-notification>
        <b-notification v-else type="is-success is-light" role="alert">
            <h3>Congratulations! You have passed this course</h3>
            <br/>
            <b-button tag="a" target="_blank" :href="'/v1/score/certificate/'+id" type="is-danger">Download
                Certificate
            </b-button>
            <b-button @click="allCourse" type="is-danger">All Courses</b-button>
        </b-notification>
    </div>
</template>
<script>
export default {
    props: ["item"],
    computed: {
        id() {
            return this.$route.params.id;
        }
    },
    methods: {
        allCourse() {
            this.$router.push({name: "home"});
        },
        certificate() {

        },
        tryAgain() {
            this.$router.push({
                name: "course.question",
                params: {id: this.item.course_id},
            });
        },
    },
};
</script>

<style scoped>
p {
    color: black;
}
</style>
